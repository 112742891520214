import { template as template_c15f5ec7033a42079de68100a8d41f13 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_c15f5ec7033a42079de68100a8d41f13(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_c15f5ec7033a42079de68100a8d41f13(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_c15f5ec7033a42079de68100a8d41f13(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
