import { template as template_a0cf345c282644e59758438e751fffcc } from "@ember/template-compiler";
const SidebarSectionMessage = template_a0cf345c282644e59758438e751fffcc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
