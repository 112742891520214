import { template as template_32d29d03a10a4454bf7b379d9f0133fc } from "@ember/template-compiler";
const FKControlMenuContainer = template_32d29d03a10a4454bf7b379d9f0133fc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
