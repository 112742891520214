import { template as template_36c3b4f8da894177a87a0f36889964fe } from "@ember/template-compiler";
const FKText = template_36c3b4f8da894177a87a0f36889964fe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
